import './scss/main.scss'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

import { OrderTypeProvider, SideBarProvider, UserCartProvider, UserInfoProvider, UserTokenProvider } from './context/GlobalContext'

import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { queryClient } from './react-query/queryClient'

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <UserTokenProvider>
        <UserInfoProvider>
          <SideBarProvider>
            <UserCartProvider>
              <OrderTypeProvider>
                <App />
              </OrderTypeProvider>
            </UserCartProvider>
          </SideBarProvider>
        </UserInfoProvider>
      </UserTokenProvider>
    </BrowserRouter>
    <ReactQueryDevtools />
  </QueryClientProvider>,
  document.getElementById('root')
)
