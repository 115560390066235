import { useState, createContext } from 'react'
import { getStoredCart } from '../storage/cartStorage'
import { getStoredToken } from '../storage/tokenStorage'
import { getStoredUser } from '../storage/userStorage'

export const SideBarContext = createContext()
export const UserCartContext = createContext()
export const UserInfoContext = createContext()
export const UserTokenContext = createContext()
export const OrderTypeContext = createContext()

export const UserTokenProvider = ({ children }) => {
  const [token, setToken] = useState(getStoredToken() ?? false)

  return <UserTokenContext.Provider value={{ token, setToken }}>{children}</UserTokenContext.Provider>
}

export const SideBarProvider = ({ children }) => {
  const [sidebar, setSidebar] = useState(false)

  return <SideBarContext.Provider value={{ sidebar, setSidebar }}>{children}</SideBarContext.Provider>
}

export const UserCartProvider = ({ children }) => {
  const [cart, setCart] = useState(getStoredCart() ?? [])

  return <UserCartContext.Provider value={{ cart, setCart }}>{children}</UserCartContext.Provider>
}

export const UserInfoProvider = ({ children }) => {
  const [user, setUser] = useState(getStoredUser() ?? {})

  return <UserInfoContext.Provider value={{ user, setUser }}>{children}</UserInfoContext.Provider>
}

export const OrderTypeProvider = ({ children }) => {
  const [orderType, setOrderType] = useState('a1')

  return <OrderTypeContext.Provider value={{ orderType, setOrderType }}>{children}</OrderTypeContext.Provider>
}
