import { useMutation, useQueryClient } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

import { toast } from 'react-toastify'

const updateOrder = async (data) => {
  const rsp = await axiosInstance(withAuthorization({
    url: `/orders/${data.id}`,
    method: 'PATCH',
    data: data.payload
  }))
  return rsp.data
}

export const useOrderUpdate = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation(updateOrder, {
    onSuccess: (rsp) => {
      queryClient.invalidateQueries([queryKeys.orders])
      toast.success('Naloga je uspesno posodobljena')
    },
    onError: () => { toast.error('Napaka pri posodabljanju naloga') }
  })
  return mutation
}

export default { useOrderUpdate }
