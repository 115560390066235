import { useMutation, useQueryClient } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

import { toast } from 'react-toastify'

const createUserStockUsage = async (data) => {
  const rsp = await axiosInstance(withAuthorization({
    url: `/users/${data.id}/stock/usage`,
    method: 'POST',
    data: data.payload
  }))
  return rsp.data
}

export const useUserStockUsageCreate = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation(createUserStockUsage, {

    onSuccess: (rsp) => {
      queryClient.invalidateQueries([queryKeys.userStock])
      toast.success('Blago je bilo uspešno porabljeno')
    },
    onError: () => { toast.error('Napaka pri porabi blaga') }
  })
  return mutation
}

export default { useUserStockUsageCreate }
