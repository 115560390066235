import { useContext } from 'react'

import Loader from '../../components/common/Loader'

import { UserInfoContext } from '../../context/GlobalContext'

import UserTransferRequestedToMe from '../../components/usertransfer/UserTransferRequestedToMe'
import UserTransferRequestedByMe from '../../components/usertransfer/UserTransferRequestedByMe'
import { useUserTransfers } from '../../api/usertransfers/useUserTransfers'

function UserTransferList (props) {
  const { user } = useContext(UserInfoContext)

  const transferRequestedByMe = useUserTransfers({ id: user.id, payload: { action: 'requestedByMe', status: 1 } })
  const transferRequestedToMe = useUserTransfers({ id: user.id, payload: { action: 'requestedToMe', status: 1 } })

  if (transferRequestedByMe.isLoading || transferRequestedToMe.isLoading) return <Loader />

  return (
    <div className='tlps-userstock'>
      {transferRequestedByMe.data.length ? transferRequestedByMe.data.map((item, index) => <UserTransferRequestedByMe key={index} item={item} />) : ''}
      {transferRequestedToMe.data.length ? transferRequestedToMe.data.map((item, index) => <UserTransferRequestedToMe key={index} item={item} />) : ''}
      {!transferRequestedByMe.data.length && !transferRequestedToMe.data.length ? <div className='not-found'>Trenutno nimate zahtev za prenos</div> : ''}
    </div>
  )
}

export default UserTransferList
