import { useContext } from 'react'

import { useUserTransferUpdate } from '../../api/usertransfers/useUserTransferUpdate'

import { UserInfoContext } from '../../context/GlobalContext'

function UserTransferRequestedToMe ({ item }) {
  const { user } = useContext(UserInfoContext)
  const mutation = useUserTransferUpdate()

  const handleAccept = (e) => {
    e.preventDefault()

    const data = { userId: user.id, transferId: item.id, payload: { status: 2 } }
    mutation.mutate(data)
  }

  const handleReject = (e) => {
    e.preventDefault()

    const data = { userId: user.id, transferId: item.id, payload: { status: 3 } }
    mutation.mutate(data)
  }

  return (
    <div className='user-transfer__item'>
      <div className='user-transfer__item--data'>
        <h5 className='user-transfer__item--title'>Zahteva za prenos iz: <span>{item.sender.name} {item.sender.surname}</span></h5>
        <p className='user-transfer__item--name'>Ime izdelka: <span>{item.product.name}</span></p>
        <p className='user-transfer__item--qty'>Količina: <span>{item.quantity}</span></p>
      </div>
      <div className='user-transfer__item--actions'>
        <button onClick={handleAccept}>Sprejmi</button>
        <button onClick={handleReject}>Zavrni</button>
      </div>
    </div>
  )
}

export default UserTransferRequestedToMe
