import { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'

import logo from '../../images/logos/logo.svg'
import fakeAvatar from '../../images/users/placeholder.png'

import { SideBarContext, UserInfoContext, UserTokenContext } from '../../context/GlobalContext'
import { getStoredUser } from '../../storage/userStorage'
import { getStoredToken } from '../../storage/tokenStorage'

function Header () {
  const { sidebar, setSidebar } = useContext(SideBarContext)
  const { user, setUser } = useContext(UserInfoContext)
  const { token, setToken } = useContext(UserTokenContext)
  const navigate = useNavigate()

  useEffect(() => {
    const localUser = getStoredUser()
    const localToken = getStoredToken()

    if (!user && localUser) {
      setUser(localUser)
    }

    if (!token && localToken) {
      setToken(localToken)
    }
  }, [])

  const avatar = user.img !== '0' && user.img !== null && user.img !== '' ? user.img : ''

  function handleSidebar () {
    setSidebar(!sidebar)
  }

  if (!token) navigate('/login')

  return (
    <div className='tlps-header'>
      <div className='tlps-header__logo'>
        <Link to='/home'><img src={logo} alt='logo' /></Link>
      </div>
      <div className='tlps-header__userdata'>
        <div className='tlps-header__update'>
          <span style={{ fontSize: 8, marginRight: 10 }}>
            {import.meta.env.VITE_VERSION}
          </span>
          <button onClick={() => { window.location.reload() }}>
            <FontAwesomeIcon icon={faSync} />
          </button>
        </div>
        <div className='tlps-header__userbox'>
          <a onClick={handleSidebar}>
            <img src={avatar !== '' ? `${import.meta.env.VITE_ERP_URL}/images/profile/${avatar}` : fakeAvatar} alt='avatar' className='avatar' />
          </a>
        </div>
      </div>
    </div>
  )
}
export default Header
