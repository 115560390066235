import Header from '../components/common/Header'
import SideBar from '../components/common/SideBar'
import { BackButton } from '../components/common/Buttons'

function UserProfile (props) {
  return (
    <div className='tlps-userprofile'>
      <Header />
      <SideBar />
      <BackButton />
      <div className='tlps-userprofile__data'>
        <input
          type='text'
          placeholder='Ime'
          name='name'
        />
        <input
          type='text'
          placeholder='Priimek'
          name='surname'
        />
        <input
          type='text'
          placeholder='Naslov'
          name='address'
        />
        <input
          type='text'
          placeholder='Kraj'
          name='city'
        />
        <input
          type='text'
          placeholder='Kontaktna številka'
          name='phone'
        />
        <input
          type='text'
          placeholder='E poštni naslov'
          name='email'
        />
      </div>
      <div className='tlps-userprofile__passwords'>
        <h3>Spremeni geslo</h3>
        <input
          type='password'
          placeholder='Novo geslo'
          name='pass'
        />
        <input
          type='password'
          placeholder='Ponovno vnesi novo geslo'
          name='confirmpass'
        />
      </div>
      <div className='tlps-userprofile__btn'>
        <button>Shrani</button>
      </div>
    </div>
  )
}

export default UserProfile
