import { useQuery } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

const getWarehouseTypes = async (params) => {
  const axiosRsp = await axiosInstance(withAuthorization({
    url: '/warehouse/types',
    method: 'GET',
    params
  }))
  return axiosRsp.data
}

export const useWarehouseTypes = (params) => {
  return useQuery([queryKeys.warehouseType, params], () => getWarehouseTypes(params))
}

export default { useWarehouseTypes }
