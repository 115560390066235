import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'

import loginBg from '../images/logos/login-bg.png'
import logo from '../images/logos/login-logo.svg'
import { UserInfoContext, UserTokenContext } from '../context/GlobalContext'

import { getFormData } from '../helpers/form'
import { login } from '../api/auth/useAuth'
import { clearStoredToken, setStoredToken } from '../storage/tokenStorage'
import { clearStoredUser, getStoredUser, setStoredUser } from '../storage/userStorage'

import { toast } from 'react-toastify'

export default function Login () {
  const { setUser } = useContext(UserInfoContext)
  const { token, setToken } = useContext(UserTokenContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (token) return navigate('/home')

    clearStoredToken()
    clearStoredUser()
  }, [token])

  const handleSubmit = (e) => {
    e.preventDefault()

    const formData = getFormData(e.target)
    login(formData)
      .then(({ user, ...tokenData }) => {
        setStoredUser(user)
        setUser(getStoredUser())
        setStoredToken(tokenData.token)
        setToken(tokenData.token)
        navigate('/home')
      })
      .catch(() => toast.error('Prišlo je do napake!'))
  }

  return (
    <div className='tlps-login'>
      <div className='tlps-login__head'>
        <img src={loginBg} alt='loginBg' className='login-bg' />
        <img src={logo} alt='logo' className='login-logo' />
      </div>
      <div className='tlps-header__update'>
        <span style={{ fontSize: 8, marginRight: 10 }}>{import.meta.env.VITE_VERSION}</span>
        <button onClick={() => window.location.reload()}>
          <FontAwesomeIcon icon={faSync} />
        </button>
      </div>
      <div className='tlps-login__data'>
        <div className='tlps-login__data-wrap'>
          <form onSubmit={handleSubmit}>
            <div className='tlps-login__data-email'>
              <input type='email' name='email' placeholder='johndoe@mail.com' />
            </div>
            <div className='tlps-login__data-password'>
              <input type='password' name='password' placeholder='********' />
            </div>
            <div className='tlps-login__data-devider' />
            <div className='tlps-login__data-btn'>
              <button type='submit' className='btn login-btn'>Vpis</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
