import { useQuery } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

const getProduct = async (id) => {
  const axiosRsp = await axiosInstance(withAuthorization({
    url: `/products/${id}`,
    method: 'GET'
  }))
  return axiosRsp.data
}

export const useProduct = (id) => {
  return useQuery([queryKeys.products, id], () => getProduct(id))
}

export default { useProduct }
