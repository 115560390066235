/**
 * Walk through a form's elements and return field values in an object
 */
export const getFormData = (formElement) => {
  const formData = {}
  for (let i = 0; i < formElement.elements.length; i++) {
    const field = formElement.elements[i]
    if (!field.name) continue
    switch (field.type) {
      case 'submit':
      case 'button':
        break
      case 'checkbox':
        formData[field.name] = field.checked ? 1 : 0
        break
      case 'radio':
        if (!field.checked) break
        formData[field.name] = field.value
        break
      case 'password':
        formData[field.name] = field.value
        break
      default:
        formData[field.name] = field.value.trim()
    }
  }
  return formData
}

export default { getFormData }
