import { useContext } from 'react'

import Header from '../components/common/Header'
import SideBar from '../components/common/SideBar'
import CartItem from '../components/cart/CartItem'

import { UserCartContext } from '../context/GlobalContext'

import { clearStoredCart } from '../storage/cartStorage'
import { useOrderCreate } from '../api/orders/useOrderCreate'
import { BackButton } from '../components/common/Buttons'

function Cart () {
  const { cart, setCart } = useContext(UserCartContext)
  const mutation = useOrderCreate()

  const handleSendCart = (e) => {
    e.preventDefault()

    mutation.mutateAsync(cart)
      .then((res) => setCart([]))
  }

  if (mutation.isSuccess) clearStoredCart()

  return (
    <div className='tlps-userstock'>
      <Header />
      <SideBar />
      <BackButton />
      <div className='cart-send-order-btn'>
        <button onClick={handleSendCart}>ODDAJ SKUPEK NAROČIL</button>
      </div>
      {cart.length
        ? (cart.map((item, index) => <CartItem key={index} item={item} />))
        : (<div className='cart-not-found-items'>Nimate naročil za oddajo</div>)}
    </div>
  )
}

export default Cart
