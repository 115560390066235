import { CircularProgress } from '@mui/material'
export const Loader = () => {
  return (
    <div className='loader'>
      <CircularProgress disableShrink color='inherit' />
    </div>
  )
}

export default Loader
