import { useState, useMemo } from 'react'
import { debounce } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import UserStockItem from '../../components/userstock/UserStockItem'

import Error from '../../components/common/Error'
import Loader from '../../components/common/Loader'
import { useUserStock } from '../../api/userstock/useUserStock'
import { useParams } from 'react-router-dom'

export const UserStockList = (props) => {
  const [filters, setFilters] = useState({})
  const [search, setSearch] = useState('')
  const { id } = useParams()
  const products = useUserStock({ id, payload: filters })

  const handleSearch = (e) => {
    setSearch(e.target.value)
    setFilters({ ...filters, productNameOrSerial: e.target.value })
  }

  const debouncedSearch = useMemo(() => debounce(handleSearch, 1000), [])

  if (products.isLoading) { return <Loader /> }

  if (products.isError) { return <Error /> }

  return (
    <div className='tlps-userstock'>
      <div className='tlps-userstock__search'>
        <input type='text' placeholder='Vnesi naziv/serijsko izdelka...' defaultValue={search} autoFocus onChange={debouncedSearch} />
        <FontAwesomeIcon icon={faSearch} />
      </div>
      {products.data.length ? products.data.map((item, index) => <UserStockItem key={index} itemData={item} />) : <div className='cart-not-found-items'>Brez rezultatov</div>}
    </div>
  )
}

export default UserStockList
