export const getStoredCart = () => {
  const storedToken = window.localStorage.getItem('tlps_cart')
  return storedToken ? JSON.parse(storedToken) : null
}

export const setStoredCart = (token) => {
  window.localStorage.setItem('tlps_cart', JSON.stringify(token))
}

export const clearStoredCart = () => {
  window.localStorage.removeItem('tlps_cart')
}

export default {
  getStoredCart,
  setStoredCart,
  clearStoredCart
}
