export default {
  token: 'token',
  users: 'users',
  orders: 'orders',
  products: 'products',
  transfers: 'transfers',
  userStock: 'userStock',
  vacations: 'vacations',
  taskUsage: 'taskUsage',
  warehouseType: 'warehouseType'
}
