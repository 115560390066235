import { Outlet } from 'react-router-dom'
import { BackButton } from '../components/common/Buttons'
import Header from '../components/common/Header'
import SideBar from '../components/common/SideBar'

const Vacations = () => {
  return (
    <div className='vacations'>
      <Header />
      <SideBar />
      <Outlet />
      <BackButton />
    </div>
  )
}

export default Vacations
