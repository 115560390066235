import { Alert } from '@mui/material'


export const InternalError = () => {
  return (
    <div className='error-wrap'>
      <Alert severity='error'>Server error</Alert>
    </div>
  )
}

export default { InternalError }
