import { useState, useMemo } from 'react'
import { debounce } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import UserStockUsageItem from '../../components/userstock/UserStockUsageItem'

import Loader from '../../components/common/Loader'
import { useParams } from 'react-router-dom'
import { useUserStock } from '../../api/userstock/useUserStock'

const UserStockUsageList = (props) => {
  const { id } = useParams()
  const [filters, setFilters] = useState({ inStock: true })
  const [search, setSearch] = useState('')
  const userStock = useUserStock({ id, payload: filters })

  const handleSearch = (e) => {
    setSearch(e.target.value)
    setFilters({ ...filters, productNameOrSerial: e.target.value })
  }

  const debouncedSearch = useMemo(() => debounce(handleSearch, 1000), [])

  if (userStock.isLoading) return <Loader />

  return (
    <div className='tlps-userusage'>
      <div className='tlps-userusage__search'>
        <input type='text' placeholder='Vnesi naziv/serijsko izdelka...' defaultValue={search} onChange={debouncedSearch} />
        <FontAwesomeIcon icon={faSearch} />
      </div>
      {userStock.data.length
        ? (userStock.data.map((item, index) => <UserStockUsageItem key={index} item={item} />))
        : <div className='cart-not-found-items'>Brez rezultatov</div>}
    </div>
  )
}

export default UserStockUsageList
