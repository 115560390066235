export const getStoredUser = () => {
  const storedUser = window.sessionStorage.getItem('tlps_user')
  return storedUser ? JSON.parse(storedUser) : null
}

export const setStoredUser = (user) => {
  window.sessionStorage.setItem('tlps_user', JSON.stringify(user))
}

export const clearStoredUser = () => {
  window.sessionStorage.removeItem('tlps_user')
}

export default {
  getStoredUser,
  setStoredUser,
  clearStoredUser
}
