import { useMutation, useQueryClient } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

import { toast } from 'react-toastify'

const createOrder = async (data) => {
  const rsp = await axiosInstance(withAuthorization({
    url: '/orders',
    method: 'POST',
    data
  }))
  return rsp.data
}

export const useOrderCreate = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation(createOrder, {

    onSuccess: (rsp) => {
      queryClient.invalidateQueries([queryKeys.orders])
      toast.success('Narucilo je uspesno kreirano')
    },
    onError: () => { toast.error('Greska prilikom kreiranja narucila') }
  })
  return mutation
}

export default { useOrderCreate }
