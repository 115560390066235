import { useState, useContext, useEffect } from 'react'
import { UserCartContext } from '../../context/GlobalContext'
import { setStoredCart } from '../../storage/cartStorage'
import { toast } from 'react-toastify'
import { Input, Submit } from '../common/Form'
import { getFormData } from '../../helpers/form'

export const ProductItem = ({ product, ...props }) => {
  const [open, setOpen] = useState(false)
  const { cart, setCart } = useContext(UserCartContext)

  useEffect(() => setStoredCart(cart), [cart])

  // add to cart handler
  const handleAddToCart = (e) => {
    e.preventDefault()

    const formData = getFormData(e.target) // get form data

    if (formData.quantity === '') return toast.error('Vnesite količino izdelka') // check if quantity is empty

    if (Number.parseInt(formData.quantity) > Number.parseInt(product.stock)) return toast.error('Nimate dovolj izdelkov na zalogi') // check if user has enough products in stock

    if (product.multiple === 1 && Number.parseInt(formData.quantity) > 1) return toast.error('Za ta izdelek je lahko količina samo 1') // check if product is multiple (quantity can be only 1)

    const index = cart.findIndex((x) => Number.parseInt(x.id) === Number.parseInt(product.id)) // find product in cart

    // update cart
    index === -1
      ? setCart([...cart, Object.assign({}, formData, { id: product.id, quantity: Number.parseInt(formData.quantity) })])
      : setCart([...cart.slice(0, index), Object.assign({}, cart[index], { quantity: Number.parseInt(formData.quantity) }), ...cart.slice(index + 1)])

    toast.success('Dodano v košarico')
  }

  return (
    <div className='tlps-userstock__item' style={{ display: product.stock === null || product.stock === 0 ? 'none' : 'block' }}>
      <div className='tlps-userstock__item-header' onClick={() => setOpen(!open)} style={open === true ? { paddingBottom: 20 } : { paddingBottom: 0 }}>
        <div className='tlps-userstock__item-header__title'>
          <h3>{product.name}</h3>
        </div>
        <div className='tlps-userstock__item-header__qty'>
          <span>{product.stock === null ? 0 : product.stock}</span>
          <p>{product.unit_short_name}</p>
        </div>
        <div className='tlps-userstock__item-header__serial'>
          <span>SN:</span>
          <span>{product.serial_num}</span>
        </div>
      </div>
      <div className='tlps-userstock__item-footer' style={open === true ? { display: 'block' } : { display: 'none' }}>
        <div className='tlps-userstock__item-footer__data'>
          <div>
            <span>KT:</span>
            <span>{product?.category?.name}</span>
          </div>
        </div>
        <form onSubmit={handleAddToCart}>
          <div className='tlps-userstock__item-footer__buttons'>
            <Input type='number' placeholder='vnesi količino' name='quantity' defaultValue={product.multiple === 1 && 1} style={{ marginBottom: 0 }} />
            <Submit className='btn-add-order m-bottom--m'>DODAJ V NAROČLO</Submit>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProductItem
