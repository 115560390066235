import React from 'react'

function UserTransferRequestedByMe({ item }) {
  return (
    <div className="user-transfer__item">
      <div className="user-transfer__item--data">
        <h5 className="user-transfer__item--title">Prošnja poslana: <span>{item.reciver.name + ' ' + item.reciver.surname}</span></h5>
        <p className="user-transfer__item--name">Ime izdelka: <span>{item.product.name}</span></p>
        <p className="user-transfer__item--qty">Količina: <span>{item.quantity}</span></p>
        <p className="user-transfer__item--loading">Čakanje na odobritev....</p>
      </div>
    </div>
  )
}

export default UserTransferRequestedByMe
