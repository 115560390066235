import { useMemo } from 'react'

export const Form = (props) => {
  return <form className='form' {...props} />
}

export const Input = ({ label, ...props }) => {
  return (
    <div className='form__input'>
      {label && <span className='form__label'>{label}</span>}
      <input className='form__control' {...props} />
    </div>
  )
}

export const Textarea = ({ label, ...props }) => {
  return (
    <div className='form__input'>
      {label && <span className='form__label'>{label}</span>}
      <textarea className='form__control' {...props} />
    </div>
  )
}

export const Submit = ({ className, ...props }) => {
  return <button className={`btn ${className}`} type='submit' {...props} />
}

export const Select = ({ options = [], id, label, hint, ...props }) => {
  const nodeId = useMemo(() => id || 'id' + Math.random().toString(16).slice(2), [id])
  return (
    <div className='form__item form__item--select'>
      <label className='form__label' htmlFor={nodeId}>{label}</label>
      <div className='form__field'>
        <select className='form__control' id={nodeId} {...props}>
          {options.map(([value, label]) => (
            <option key={nodeId + value} value={value}>{label}</option>
          ))}
        </select>
      </div>
      {hint && <p className='form__hint'>{hint}</p>}
    </div>
  )
}

export default { Input, Textarea, Form, Submit }
