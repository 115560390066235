import { useMutation, useQueryClient } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

import { toast } from 'react-toastify'

const updateUserStock = async (data) => {
  const rsp = await axiosInstance(withAuthorization({
    url: `/users/${data.id}/stock/`,
    method: 'PATCH',
    data: data.payload
  }))
  return rsp.data
}

export const useUserStockUpdate = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation(updateUserStock, {

    onSuccess: (rsp) => {
      queryClient.invalidateQueries([queryKeys.userStock])
      queryClient.invalidateQueries([queryKeys.users])
      toast.success('Zahtevano je vračilo zalog')
    },
    onError: () => { toast.error('Vračilo zalog ni uspelo') }
  })
  return mutation
}

export default { useUserStockUpdate }
