import { useState, useEffect, useContext } from 'react'
import { useProduct } from '../../api/products/useProduct'
import { clearStoredCart, setStoredCart } from '../../storage/cartStorage'
import { UserCartContext } from '../../context/GlobalContext'
import Loader from '../common/Loader'
import { Input, Submit } from '../common/Form'
import { getFormData } from '../../helpers/form'
import { toast } from 'react-toastify'

function CartItem ({ item, ...props }) {
  const [open, setOpen] = useState(false)
  const [touchStart, setTouchStart] = useState(0)
  const [touchMove, setTouchMove] = useState()
  const { cart, setCart } = useContext(UserCartContext)
  const product = useProduct(item.id)

  useEffect(() => { setStoredCart(cart) }, [cart])

  // update cart handler
  const handleUpdateCart = (e) => {
    e.preventDefault()
    const formData = getFormData(e.target)
    const { data } = product

    if (formData.quantity === '') return toast.error('Vnesite količino izdelka')

    if (data.multiple === 1) return toast.error('Za ta izdelek je lahko količina samo 1')

    if (Number.parseInt(formData.quantity) > Number.parseInt(data.stock)) return toast.error('Ni dovolj izdelkov na zalogi')

    const index = cart.findIndex((x) => x.id === data.id)

    setCart([...cart.slice(0, index), Object.assign({}, cart[index], { quantity: Number.parseInt(formData.quantity) }), ...cart.slice(index + 1)])

    toast.success('Posodobljeno')
  }

  // remove from cart handler
  const handleRemoveFromCart = (e) => {
    if (touchMove > 150 || touchMove < -150) {
      if (cart.length === 1) clearStoredCart()

      const index = cart.findIndex((x) => x.id === item.id)
      setCart([...cart.slice(0, index), ...cart.slice(index + 1)])
    }
    setTouchMove(0)
  }

  if (product.isLoading) return <Loader />

  return (
    <div
      className='tlps-userstock__item'
      onTouchMove={(e) => setTouchMove(e.targetTouches[0].clientX - touchStart)}
      onTouchStart={(e) => setTouchStart(e.targetTouches[0].clientX)}
      onTouchEnd={handleRemoveFromCart}
      style={{ transform: `translateX(${touchMove}px)` }}
    >
      <div className='tlps-userstock__item-header' onClick={() => setOpen(!open)} style={open === true ? { paddingBottom: 20 } : { paddingBottom: 0 }}>
        <div className='tlps-userstock__item-header__title'><h3>{product.data.name}</h3></div>
        <div className='tlps-userstock__item-header__qty'><span>{item.quantity}</span><p>kos</p></div>
      </div>
      <div className='tlps-userstock__item-footer' style={open === true ? { display: 'block' } : { display: 'none' }}>
        <div className='tlps-userstock__item-footer__data'>
          <div>
            <span>SN:</span><span>{product.data.serial_num}</span>
          </div>
          <div>
            <span>KT:</span><span>{product.data.category?.name}</span>
          </div>
        </div>
        <form onSubmit={handleUpdateCart}>
          <div className='tlps-userstock__item-footer__buttons'>
            <Input type='hidden' name='id' defaultValue={item.id} />
            <Input type='number' placeholder='vnesi količino' name='quantity' defaultValue={product.multiple === 1 && 1} style={{ marginBottom: 0 }} />
            <Submit className='btn-add-order m-bottom--m'>DODAJ V NAROČLO</Submit>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CartItem
