import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export const InternalLinkBtn = ({ className, children, ...props }) => {
  return <Link className={`internal-link-btn ${className}`} {...props}>{children}</Link>
}

export const BackButton = ({ to = -1, ...props }) => {
  const navigate = useNavigate()
  return (
    <div className='tlps-backbtn'><button onClick={() => navigate(to)}><FontAwesomeIcon icon={faArrowLeft} /></button></div>
  )
}
