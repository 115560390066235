import { useState, useMemo } from 'react'
import { debounce } from 'lodash'
import { useProducts } from '../../api/products/useProducts'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import ProductItem from '../../components/products/ProductItem'
import Loader from '../../components/common/Loader'
import { InternalError } from '../../components/common/Error'

export const ProductsList = (props) => {
  const [filters, setFilters] = useState({})
  const products = useProducts(filters)
  const [search, setSearch] = useState(null)

  const handleSearch = (e) => {
    setSearch(e.target.value)
    setFilters({ ...filters, nameOrSerial: e.target.value })
  }

  const debouncedSearch = useMemo(() => debounce(handleSearch, 1000), [])

  if (products.isLoading) { return <Loader /> }

  if (products.isError) { return <InternalError /> }

  return (
    <div>
      <div className='tlps-userstock__search'>
        <input type='text' placeholder='Vnesi naziv/serijsko izdelka...' defaultValue={search} onChange={debouncedSearch} />
        <FontAwesomeIcon icon={faSearch} />
      </div>
      {products.data.length ? products.data.map((item) => <ProductItem key={item.id} product={item} />) : <div className='cart-not-found-items'>Brez rezultatov</div>}
    </div>
  )
}

export default ProductsList
