import { useMutation, useQueryClient } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

import { toast } from 'react-toastify'

const createTransfer = async (data) => {
  const rsp = await axiosInstance(withAuthorization({
    url: `/users/${data.id}/transfer`,
    method: 'POST',
    data: data.payload
  }))
  return rsp.data
}

export const useUserTransferCreate = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation(createTransfer, {

    onSuccess: (rsp) => {
      queryClient.invalidateQueries([queryKeys.transfers])
      toast.success('Transfer je uspesno kreiran')
    },
    onError: () => { toast.error('Greska prilikom kreiranja transfera') }
  })
  return mutation
}

export default { useUserTransferCreate }
