import { Router } from './router'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function App () {
  return (
    <div className='tlps-main'>
      <Router />
      <ToastContainer autoClose={2000} transition={Slide} hideProgressBar pauseOnFocusLoss={false} closeOnClick={false} draggable theme='colored' />
    </div>
  )
}
