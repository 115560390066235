import { useQuery } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

const getOrder = async ({ id, ...params }) => {
  const axiosRsp = await axiosInstance(withAuthorization({
    url: `/orders/${id}`,
    method: 'GET',
    params
  }))
  return axiosRsp.data
}

export const useOrder = (params) => {
  return useQuery([queryKeys.orders, params.id], () => getOrder(params))
}

export default { useOrder }
