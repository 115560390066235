import { useMutation, useQueryClient } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

import { toast } from 'react-toastify'

const updateTransfer = async (data) => {
  const rsp = await axiosInstance(withAuthorization({
    url: `/users/${data.userId}/transfer/${data.transferId}`,
    method: 'PATCH',
    data: data.payload
  }))
  return rsp.data
}

export const useUserTransferUpdate = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation(updateTransfer, {

    onSuccess: (rsp) => {
      queryClient.invalidateQueries([queryKeys.transfers])
      queryClient.invalidateQueries([queryKeys.products])
      toast.success('Prenos je uspesno posodobljen')
    },
    onError: () => { toast.error('Napaka pri posodabljanju prenosa') }
  })
  return mutation
}

export default { useUserTransferUpdate }
