// import { QueryClient } from '@tanstack/react-query'
import { QueryClient } from 'react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 120000, // 2 min
      cacheTime: 0, // 5 min
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: import.meta.env.NODE_ENV === 'production' ? 2 : false
    }
  }
})

export default { queryClient }
