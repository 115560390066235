import { useQuery } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

const getUsers = async (params) => {
  const axiosRsp = await axiosInstance(withAuthorization({
    url: '/users',
    method: 'GET',
    params
  }))
  return axiosRsp.data
}

export const useUsers = (filters) => {
  return useQuery([queryKeys.users, filters], () => getUsers(filters))
}

export default { useUsers }
