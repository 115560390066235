import React, { useState, useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import Loader from '../../components/common/Loader'
import { Form, Submit } from '../../components/common/Form'
import { InternalLinkBtn } from '../../components/common/Buttons'
import { getFormData } from '../../helpers/form'
import { UserInfoContext, OrderTypeContext } from '../../context/GlobalContext'
import { useOrder } from '../../api/orders/useOrder'
import { useOrderUpdate } from '../../api/orders/useOrderUpdate'
import OrderA1 from '../../components/orders/OrderA1'

export const TaskShow = (props) => {
  const { id } = useParams()
  const { user } = useContext(UserInfoContext)
  const { orderType } = useContext(OrderTypeContext)
  const [formDisabled, setFormDisabled] = useState(false)
  const order = useOrder({ id, type: orderType })
  const mutation = useOrderUpdate()
  const { data } = order

  useEffect(() => {
    if (data && user.id !== data.supervisor_id) { setFormDisabled(false) }
  }, [order])

  const handleOnSubmit = (e) => {
    e.preventDefault()
    const formData = getFormData(e.target)
    formData.type = orderType

    mutation.mutate({ id, payload: formData })
  }

  if (order.isLoading || mutation.isLoading) return <Loader />

  const realData = data.updates ?? data

  const mapView = {
    a1: <OrderA1 data={realData} />
  }

  return (
    <div className='tlps-singleorder' style={{ marginBottom: 70 }}>
      <div className='m-bottom--xl'>
        <InternalLinkBtn disabled={formDisabled} to={`/tasks/${id}/usage`} className='btn--red btn btn--padding-10-30'>Razknjizi zalogu</InternalLinkBtn>
      </div>
      <h2 className='m-bottom--xl'>Nalog #{realData.serial}</h2>
      <Form onSubmit={handleOnSubmit} disabled={formDisabled}>
        {mapView[orderType]}
        <Submit disabled={formDisabled} className='btn--red btn--padding-10-30 m-top--l'>Shrani</Submit>
      </Form>
    </div>
  )
}

export default TaskShow
