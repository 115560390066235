import { useQuery } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

const getVacations = async (params) => {
  const axiosRsp = await axiosInstance(withAuthorization({
    url: '/vacations',
    method: 'GET',
    params
  }))
  return axiosRsp.data
}

export const useVacations = (params) => {
  return useQuery([queryKeys.vacations, params], () => getVacations(params))
}

export default { useVacations }
