import { Link } from 'react-router-dom'

import Loader from '../../components/common/Loader'
import { useVacations } from '../../api/vacations/useVacations'

const VacationsList = (props) => {
  const vacations = useVacations()

  const statusArr = {
    0: [{ color: '#a19f9c', text: 'v teku …' }],
    1: [{ color: '#51c03e', text: 'odobreno' }],
    2: [{ color: '#ef2119', text: 'zavrnjeno' }]
  }

  if (vacations.isLoading) return <Loader />

  return (
    <div className='tlps-vacations tlps-home'>
      <div className='tlps-home__buttons'>
        <Link className='btn' to='/vacations/create'>ODDAJ ZAHTEVO</Link>
      </div>
      <div className='tlps-vacations'>
        {vacations.data.map(item => (
          <div className='tlps-userstock__item' key={item.id}>
            <h3 className='vacation-date__title'>Dopust</h3>
            <div className='vacation_item_date'>
              <span>Začetek: </span>
              <span>{item.from}</span>
            </div>
            <div className='vacation_item_date'>
              <span>Konec: </span>
              <span>{item.to}</span>
            </div>
            <div>
              {statusArr[item.status].map((item, index) => <div className='vacation_status' key={index} style={{ backgroundColor: item.color }}>{item.text}</div>)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default VacationsList
