import { Route, Routes } from 'react-router-dom'

// import NotFound from './view/NotFound';
import Login from './pages/Login'
import Home from './pages/Home'
import Cart from './pages/Cart'
import UserProfile from './pages/UserProfile'

import Vacations from './pages/Vacations'
import VacationsList from './view/vacations/VacationsList'
import VacationsCreate from './view/vacations/VacationsCreate'

import ProductsList from './view/products/ProductsList'

import Users from './pages/Users'
import UserStockList from './view/userstock/UserStockList'
import UserTransferList from './view/usertransfer/UserTransferList'
import UserStockReturnList from './view/userstockreturn/UserStockReturnList'
import UserStockUsageList from './view/userstockusage/UserStockUsageList'

import Tasks from './pages/Tasks'
import TaskList from './view/tasks/TaskList'
import TaskShow from './view/tasks/TaskShow'
import TasksUsageList from './view/tasksusage/TasksUsageList'
import Products from './pages/Products'

export function Router () {
  return (
    <Routes>
      <Route exact path='/' element={<Login />} />
      <Route path='home' element={<Home />} />
      <Route path='cart' element={<Cart />} />
      <Route path='userprofile' element={<UserProfile />} />

      <Route path='products' element={<Products />}>
        <Route index element={<ProductsList />} />
      </Route>

      <Route path='tasks' element={<Tasks />}>
        <Route index element={<TaskList />} />
        <Route path='/tasks/:id' element={<TaskShow />} />
        <Route path='/tasks/:id/usage' element={<TasksUsageList />} />
      </Route>

      <Route path='user/:id/stock' element={<Users />}>
        <Route index element={<UserStockList />} />
        <Route path='transfer' element={<UserTransferList />} />
        <Route path='usage' element={<UserStockUsageList />} />
        <Route path='return' element={<UserStockReturnList />} />
      </Route>

      <Route path='vacations' element={<Vacations />}>
        <Route index element={<VacationsList />} />
        <Route path='create' element={<VacationsCreate />} />
      </Route>

      <Route path='*' element={<Login />} />
    </Routes>
  )
}
