
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Grid, TextField } from '@mui/material'
import TextareaAutosize from '@mui/base/TextareaAutosize'

const OrderA1 = ({ data, ...props }) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4}>
          <TextField label='Tip' variant='standard' value={data.type.name} disabled required />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField label='Podtip' variant='standard' InputLabelProps={{ shrink: true }} value={data.sub_type.name} disabled required />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField label='Serijska številka' variant='standard' InputLabelProps={{ shrink: true }} value={data.serial} disabled required />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField label='Kod' variant='standard' InputLabelProps={{ shrink: true }} value={data.code} disabled required />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField label='Nadzornik' variant='standard' InputLabelProps={{ shrink: true }} value={`${data.supervisor.name} ${data.supervisor.surname}`} disabled required />
        </Grid>
      </Grid>
      <div className='user-other__title m-top--xl'>Drugi delavci</div>
      <ul className='user-other__content'>
        {data.other_supervisors_prop ? data.other_supervisors_prop.map((user, index) => <li key={index}>{user.name} {user.surname}</li>) : 'Nimate dodanih uporabnikov'}
      </ul>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
          <Typography>INFO</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <TextField label='Ime in priimek' variant='standard' value={data.full_name} disabled required />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField label='Ulica' name='street' variant='standard' InputLabelProps={{ shrink: true }} value={data.street} />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField label='Mesto' name='city' variant='standard' InputLabelProps={{ shrink: true }} value={data.city} />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField label='Kontakt' name='phone' variant='standard' InputLabelProps={{ shrink: true }} value={data.phone} />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField label='Stanovanje' name='house_number' variant='standard' InputLabelProps={{ shrink: true }} value={data.house_number} />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                label='Datum prejema narocila'
                type='date' name='order_receipt_date'
                variant='standard'
                InputLabelProps={{ shrink: true }}
                defaultValue={new Date(data.order_receipt_date).toLocaleDateString('en-CA')}
              />
            </Grid>
            <Grid item xs={12}>
              Opomba
              <TextareaAutosize label='Opomba' name='note' variant='standard' value={data.note} minRows={3} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel2a-content' id='panel2a-header'>
          <Typography>GD</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <TextField label='Datum izvedbe' type='date' name='implementation_date' variant='standard' InputLabelProps={{ shrink: true }} value={data.implementation_date} />
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize label='Opomba' name='implementation_note' variant='standard' value={data.implementation_note} minRows={3} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel2a-content' id='panel2a-header'>
          <Typography>VLEKA</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <TextField label='Datum izvedbe uvlacenje' type='date' name='retractions_date' variant='standard' InputLabelProps={{ shrink: true }} defaultValue={new Date(data.retractions_date).toLocaleDateString('en-CA')} />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField label='Odčitek kabla - začetek' name='retractions_wire_start' variant='standard' InputLabelProps={{ shrink: true }} value={data.retractions_wire_start} />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField label='Odčitek kabla - konec' name='retractions_wire_end' variant='standard' InputLabelProps={{ shrink: true }} value={data.retractions_wire_end} />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField label='Dolžina (m)' name='retractions_wire_length' variant='standard' InputLabelProps={{ shrink: true }} value={data.retractions_wire_length} />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField label='Tip kabla' name='retractions_wire_type' variant='standard' InputLabelProps={{ shrink: true }} value={data.retractions_wire_type} />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField label='Vrsta kabla' name='retractions_wire_model' variant='standard' InputLabelProps={{ shrink: true }} value={data.retractions_wire_model} />
            </Grid>
            <Grid item xs={12}>
              Opomba
              <TextareaAutosize label='Opomba' name='retractions_note' variant='standard' value={data.retractions_note} minRows={3} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel2a-content' id='panel2a-header'>
          <Typography>INŠTALACIJA</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <TextField
                label='Datum izvedbe inštalacije'
                type='date' name='installation_date'
                variant='standard'
                InputLabelProps={{ shrink: true }}
                defaultValue={new Date(data.installation_date).toLocaleDateString('en-CA')}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField label='Varjenje / meritev' name='installation_measurement' variant='standard' InputLabelProps={{ shrink: true }} value={data.installation_measurement} />
            </Grid>
            <Grid item xs={12}>
              Opomba inštalacije
              <TextareaAutosize label='Opomba inštalacije' name='installation_note' variant='standard' value={data.installation_note} minRows={3} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default OrderA1
