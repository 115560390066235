import { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { debounce } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCartArrowDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import Loader from '../../components/common/Loader'

import { useUserStock } from '../../api/userstock/useUserStock'
import { toast } from 'react-toastify'
import { getFormData } from '../../helpers/form'
import { useUserStockUpdate } from '../../api/userstock/useUserStockUpdate'

function UserStockReturnList (props) {
  const { id } = useParams()
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState({})
  const [cart, setCart] = useState([])

  const userStock = useUserStock({ id, payload: filters })
  const mutation = useUserStockUpdate()

  const handleSearch = (e) => {
    setSearch(e.target.value)
    setFilters({ ...filters, productNameOrSerial: e.target.value })
  }

  const debouncedSearch = useMemo(() => debounce(handleSearch, 1000), [])

  const handleSentReturnCart = (e) => {
    e.preventDefault()

    if (cart.length < 1) return toast.error('Košarica je prazna!')

    mutation.mutate({ id, payload: cart })
    setCart([])
  }

  function handleEmptyReturnCart () {
    if (cart.length < 1) return toast.error('Košarica je prazna!')

    setCart([])
  }

  const handleUpdateCart = (e) => {
    e.preventDefault()

    const formData = getFormData(e.target)

    if (formData.quantity === '' || formData.quantity === '0') return toast.error('Vnesi količino!')

    const productFind = userStock.data.filter(item => item.product.id === Number.parseInt(formData.product_id, 10))

    if (Number.parseInt(formData.quantity, 10) > productFind[0].stock) return toast.error('Količina je večja od zaloge!')

    const cartIndex = cart.findIndex(item => item.product_id === Number.parseInt(formData.product_id, 10))

    // Update cart state
    if (cartIndex !== -1) {
      const cartCopy = [...cart]
      cartCopy[cartIndex] = { ...cartCopy[cartIndex], quantity: formData.quantity }
      setCart(cartCopy)
    } else {
      setCart([...cart, { quantity: formData.quantity, product_id: productFind[0].product_id }])
    }
  }

  if (userStock.isLoading || mutation.isLoading) return <Loader />

  return (
    <div className='tlps-userstock'>
      <div className='tlps-userstock__search'>
        <input type='text' placeholder='Vnesi naziv/serijsko izdelka...' defaultValue={search} onChange={debouncedSearch} />
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <button onClick={handleSentReturnCart} className='btn tlps-return-stock__btn'>Pošlji v skladišče <span><span>{cart.length}</span> <FontAwesomeIcon icon={faCartArrowDown} /></span></button>
      <button onClick={handleEmptyReturnCart} className='btn tlps-return-stock__btn' style={{ marginLeft: 5 }}><FontAwesomeIcon icon={faTimes} /></button>
      {userStock.data.length
        ? (
            userStock.data
              .map((item, index) => (
                <div className='tlps-userstock__item' key={index}>
                  <div className='tlps-userstock__item-header'>
                    <div className='tlps-userstock__item-header__title'>
                      <h3>{item.product.name}</h3>
                    </div>
                    <div className='tlps-userstock__item-header__qty'>
                      <span>{item.stock}</span>
                      <p>{item.unit_short_name}</p>
                    </div>
                    <div className='tlps-userstock__item-header__serial'>
                      <span>SN:</span>
                      <span>{item.product.serial_num}</span>
                    </div>
                  </div>
                  <div>
                    <div className='tlps-userstock__item-footer__data' style={{ marginBottom: 13 }}>
                      <div>
                        <span>KT:</span>
                        <span>{item.product.category.name}</span>
                      </div>
                    </div>
                    <form onSubmit={handleUpdateCart}>
                      <div className='tlps-userstock__item-footer__buttons'>
                        <input type='hidden' name='product_id' value={item.product.id} />
                        <input type='number' placeholder='vnesi količino' name='quantity' />
                        <button className='btn btn-add-order' type='submit'>DODAJ</button>
                      </div>
                    </form>
                  </div>
                </div>
              ))
          )
        : <div className='cart-not-found-items'>Brez rezultatov</div>}
    </div>
  )
}

export default UserStockReturnList
