import { useQuery } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

const getOrders = async (params) => {
  const axiosRsp = await axiosInstance(withAuthorization({
    url: '/orders',
    method: 'GET',
    params
  }))
  return axiosRsp.data
}

export const useOrders = (filters) => {
  return useQuery([queryKeys.orders, filters], () => getOrders(filters))
}

export default { useOrders }
