import { useQuery } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

const getTransfers = async (params) => {
  const axiosRsp = await axiosInstance(withAuthorization({
    url: `/users/${params.id}/transfer`,
    method: 'GET',
    params: params.payload
  }))
  return axiosRsp.data
}

export const useUserTransfers = (filters) => {
  return useQuery([queryKeys.transfers, filters], () => getTransfers(filters))
}

export default { useUserTransfers }
