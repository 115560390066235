import { useState, useContext, useEffect } from 'react'

import UserTransferActions from '../usertransfer/UserTransferActions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'

import { UserCartContext } from '../../context/GlobalContext'
import { Input, Submit } from '../common/Form'
import { getFormData } from '../../helpers/form'
import { toast } from 'react-toastify'
import { setStoredCart } from '../../storage/cartStorage'

function UserStockItem ({ itemData }) {
  const [open, setOpen] = useState(false)
  const { cart, setCart } = useContext(UserCartContext)
  const [actionsOpen, setActionsOpen] = useState(false)

  useEffect(() => { setStoredCart(cart) }, [cart])

  const handleAddToCart = (e) => {
    e.preventDefault()

    const formData = getFormData(e.target) // get form data

    if (formData.quantity === '') return toast.error('Vnesite količino izdelka') // check if quantity is empty

    if (Number.parseInt(formData.quantity) > Number.parseInt(itemData.product.stock)) return toast.error('Nimate dovolj izdelkov na zalogi') // check if user has enough products in stock

    if (itemData.product.multiple === 1 && Number.parseInt(formData.quantity) > 1) return toast.error('Za ta izdelek je lahko količina samo 1') // check if product is multiple (quantity can be only 1)

    const index = cart.findIndex((x) => Number.parseInt(x.id) === Number.parseInt(itemData.product.id)) // find product in cart

    // update cart
    index === -1
      ? setCart([...cart, Object.assign({}, formData, { id: itemData.product.id, quantity: Number.parseInt(formData.quantity) })])
      : setCart([...cart.slice(0, index), Object.assign({}, cart[index], { quantity: Number.parseInt(formData.quantity) }), ...cart.slice(index + 1)])

    toast.success('Dodano v košarico')
  }

  return (
    <div className='tlps-userstock__item'>
      <div className='tlps-userstock__item-header' onClick={() => setOpen(!open)} style={open === true ? { paddingBottom: 20 } : { paddingBottom: 0 }}>
        <div className='tlps-userstock__item-header__title'>
          <h3>{itemData.product.name}</h3>
        </div>
        <div className='tlps-userstock__item-header__qty'>
          <span>{itemData.stock}</span>
          <p>{itemData.product.unit_short_name}</p>
        </div>
        <div className='tlps-userstock__item-header__serial'>
          <span>SN:</span>
          <span>{itemData.product.serial_num}</span>
        </div>
      </div>
      <div className={(actionsOpen) ? 'tlps-userstock__item-footer has-tf has-tf-open' : 'tlps-userstock__item-footer has-tf'} style={open === true ? { display: 'block' } : { display: 'none' }}>
        <div className='tlps-userstock__item-footer__data'>
          <div>
            <span>KT:</span>
            <span>{itemData?.product?.category?.name}</span>
          </div>
          {itemData.stock > 0 && <button className='tf-actions__open' onClick={() => setActionsOpen(!actionsOpen)}><FontAwesomeIcon icon={faExchangeAlt} /></button>}
        </div>
        <UserTransferActions product_id={itemData.product.id} max_stock={itemData.stock} actions={{ actionsOpen, setActionsOpen }} />
        <form onSubmit={handleAddToCart}>
          <div className='tlps-userstock__item-footer__buttons'>
            <Input type='number' placeholder='vnesi količino' name='quantity' defaultValue={itemData.product.multiple === 1 && 1} style={{ marginBottom: 0 }} />
            <Submit className='btn-add-order m-bottom--m'>DODAJ V NAROČLO</Submit>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UserStockItem
