import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useUsers } from '../../api/users/useUsers'

import { useUserTransferCreate } from '../../api/usertransfers/useUserTransferCreate'

import { UserInfoContext } from '../../context/GlobalContext'
import { getFormData } from '../../helpers/form'
import Loader from '../common/Loader'

function UserTransferActions (props) {
  const { user } = useContext(UserInfoContext)

  const receivers = useUsers()
  const mutation = useUserTransferCreate()

  const handleSubmit = (e) => {
    e.preventDefault()

    const formData = getFormData(e.target)
    formData.status_id = 1
    formData.product_id = props.product_id

    if (formData.quantity === 0 || formData.quantity === '') {
      toast.error('Kolicina mora biti veca od 0')
      return false
    }

    if (formData.quantity > props.max_stock) {
      toast.error(`Kolicina mora biti manja od ${props.max_stock}`)
      return false
    }

    const data = { id: user.id, payload: formData }

    mutation.mutate(data)
  }

  if (receivers.isLoading || mutation.isLoading) return <Loader />

  return (
    <form onSubmit={handleSubmit}>
      <div className='tf-actions'>
        <div className='tf-actions__select'>
          <select name='user_id' id='select_reciver'>
            {receivers ? receivers.data.map((item, index) => <option key={index} value={item.id}>{item.name + ' ' + item.surname}</option>) : ''}
          </select>
        </div>
        <div className='tf-actions__qty'>
          <input type='number' name='quantity' placeholder='kolicina' />
        </div>
        <div className='tf-actions__btn'>
          <button type='submit'>ODDAJ</button>
        </div>
      </div>
    </form>

  )
}

export default UserTransferActions
