import { useMutation, useQueryClient } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

import { toast } from 'react-toastify'

const createVacations = async (data) => {
  const rsp = await axiosInstance(withAuthorization({
    url: '/vacations',
    method: 'POST',
    data
  }))
  return rsp.data
}

export const useVacationsCreate = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation(createVacations, {

    onSuccess: (rsp) => {
      queryClient.invalidateQueries([queryKeys.vacations])
      toast.success('Prošnja za dopust je bila uspešno ustvarjena')
    },
    onError: () => { toast.error('Napaka pri ustvarjanju zahteve') }
  })
  return mutation
}

export default { useVacationsCreate }
