import { useState, useContext, useMemo } from 'react'
import { debounce } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCartArrowDown, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import Loader from '../../components/common/Loader'

import { UserInfoContext, OrderTypeContext } from '../../context/GlobalContext'

import { toast } from 'react-toastify'
import { useUserStock } from '../../api/userstock/useUserStock'
import { Input, Submit } from '../../components/common/Form'
import { getFormData } from '../../helpers/form'
import { useOrderUsageCreate } from '../../api/ordersusage/useOrderUsageCreate'
import { useParams } from 'react-router-dom'

const TasksUsageList = (props) => {
  const { id } = useParams()
  const [filters, setFilters] = useState({ inStock: true })
  const { user } = useContext(UserInfoContext)
  const { orderType } = useContext(OrderTypeContext)
  const [search, setSearch] = useState('')
  const [cart, setCart] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const userStock = useUserStock({ id: user.id, payload: filters })
  const mutation = useOrderUsageCreate()

  const handleSearch = (e) => {
    setSearch(e.target.value)
    setFilters({ ...filters, productNameOrSerial: e.target.value })
  }

  const debouncedSearch = useMemo(() => debounce(handleSearch, 1000), [])

  const handleSubmitCart = () => {
    if (cart.length < 1) return toast.error('Nimate izdelkov, ki bi jih vrnili v skladišče')

    mutation.mutateAsync({ id, payload: cart, params: { orderType } })
      .then(() => setShowPopup(!showPopup))
  }

  const handleClearCart = () => {
    if (cart.length < 1) return toast.error('Nimate izdelkov, ki bi jih vrnili v skladišče')
    setCart([])
  }

  const handleRemoveCartItem = (productId) => {
    const newCart = cart.filter((item) => item.productId !== productId)
    setCart(newCart)
  }

  const handleAddToCart = (e) => {
    e.preventDefault()

    const formData = getFormData(e.target) // get form data

    if (formData.quantity === '') return toast.error('Vnesite količino izdelka') // check if quantity is empty

    const userStockProduct = userStock.data.find((item) => item.product.id === parseInt(formData.productId)) // find product in user stock

    if (Number.parseInt(formData.quantity) > userStockProduct.stock) return toast.error('Nimate dovolj izdelkov na zalogi') // check if user has enough products in stock

    if (userStockProduct.product.multiple === 1 && Number.parseInt(formData.quantity) > 1) return toast.error('Za ta izdelek je lahko količina samo 1') // check if product is multiple (quantity can be only 1)

    const index = cart.findIndex((x) => Number.parseInt(x.productId) === Number.parseInt(formData.productId)) // find product in cart

    index === -1
      ? setCart([...cart, Object.assign({}, formData, { productName: userStockProduct.product.name, productId: formData.productId, quantity: Number.parseInt(formData.quantity) })])
      : setCart([...cart.slice(0, index), Object.assign({}, cart[index], { quantity: Number.parseInt(formData.quantity) }), ...cart.slice(index + 1)])
  }

  if (userStock.isLoading) return <Loader />

  return (
    <div className='tlps-singleorder'>
      {showPopup &&
        <div className='returnOrderPopup tlps-userstock__item flex flex--vertical flex--apart'>
          <div className='tlps-backbtn closePopup flex flex--apart flex--middle'>
            <div className='cartPreview-title'><h3>Predogled košarice</h3></div>
            <button onClick={() => setShowPopup(!showPopup)} className='btn'>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className='selectedItemsWrapper'>
            {cart.length
              ? (cart.map((item) => (
                <div className='item--wrapper flex flex--autosize flex--vertical' key={item.productId}>
                  <div className='flex__item product-name t--uppercase'>{item.productName}</div>
                  <div className='flex__item flex'>
                    <div className='flex__item product-qty'>Količina:<span className='value'> {item.quantity}</span></div>
                    <div className='flex__item product-delete btn' onClick={() => handleRemoveCartItem(item.productId)}>Odstrani</div>
                  </div>
                </div>)))
              : (
                <div>Vaša košarica je prazna...</div>)}
          </div>
          <button onClick={handleSubmitCart} className='btn tlps-return-stock__btn'>Pošlji v skladišče <span><span>{cart.length}</span> <FontAwesomeIcon icon={faCartArrowDown} /></span></button>
        </div>}
      <div className='tlps-userstock__search'>
        <input type='text' placeholder='Vnesi naziv/serijsko izdelka...' defaultValue={search} onChange={debouncedSearch} />
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <button onClick={() => setShowPopup(!showPopup)} className='btn tlps-return-stock__btn'>Predogled <span><span>{cart.length}</span> <FontAwesomeIcon icon={faCartArrowDown} /></span></button>
      <button onClick={handleClearCart} className='btn tlps-return-stock__btn' style={{ marginLeft: 5 }}><FontAwesomeIcon icon={faTrash} /></button>
      {userStock
        ? (userStock.data.map((item, index) => (
          <div className='tlps-userstock__item' key={index}>
            <div className='tlps-userstock__item-header'>
              <div className='tlps-userstock__item-header__title'>
                <h3>{item.product.name}</h3>
              </div>
              <div className='tlps-userstock__item-header__qty'>
                <span>{item.stock}</span>
                <p>{item.unit_short_name}</p>
              </div>
              <div className='tlps-userstock__item-header__serial'>
                <span>SN:</span>
                <span>{item.product.serial_num}</span>
              </div>
            </div>
            <div>
              <div className='tlps-userstock__item-footer__data' style={{ marginBottom: 13 }}>
                <div>
                  <span>KT:</span>
                  <span>{item.product.category.name}</span>
                </div>
              </div>
              <form onSubmit={handleAddToCart}>
                <div className='tlps-userstock__item-footer__buttons'>
                  <Input type='hidden' name='productId' defaultValue={item.product.id} />
                  <Input type='number' placeholder='vnesi količino' name='quantity' defaultValue={item.product.multiple === 1 && 1} style={{ marginBottom: 0 }} />
                  <Submit className='btn-add-order m-bottom--m'>DODAJ</Submit>
                </div>
              </form>
            </div>
          </div>)))
        : (<Loader />)}
    </div>
  )
}

export default TasksUsageList
