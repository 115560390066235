import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { SideBarContext, UserInfoContext, UserTokenContext } from '../../context/GlobalContext'
import { clearStoredToken } from '../../storage/tokenStorage'
import { clearStoredUser } from '../../storage/userStorage'
import { getStoredCart } from '../../storage/cartStorage'

function SideBar () {
  const { sidebar, setSidebar } = useContext(SideBarContext)
  const { user, setUser } = useContext(UserInfoContext)
  const { setToken } = useContext(UserTokenContext)

  const avatar = user.img !== '0' && user.img !== null ? user.img : ''

  const localStorageCart = getStoredCart()
  const localStorageCartNum = localStorageCart !== null ? localStorageCart.length : 0

  function handleSidebarClose () { setSidebar(!sidebar) }

  function handleLogout () {
    setUser({}) // clear user data from context
    setToken(false) // Remove token from context
    clearStoredToken() // clear token from local storage
    clearStoredUser() // clear user data from local storage
  }

  return (
    <div className={sidebar === true ? 'tlps-sidebar active' : 'tlps-sidebar'}>
      <div className='tlps-sidebar__wrap'>
        <div className='tlps-sidebar__wrap-head'>
          <FontAwesomeIcon icon={faTimes} onClick={handleSidebarClose} />
          <div className='tlps-sidebar__wrap-userbox'>
            <img src={avatar !== '' ? `${import.meta.env.VITE_ERP_URL}/images/profile/${avatar}` : `${import.meta.env.VITE_ERP_URL}/images/profile/avatar-placeholder.png`} alt='avatar' />
          </div>
        </div>
        <div className='tlps-sidebar__wrap-nav'>
          <ul>
            <Link to={`/user/${user.id}/stock`} onClick={handleSidebarClose}>Moja Zaloga</Link>
            <Link to={`/user/${user.id}/stock/usage`} onClick={handleSidebarClose}>Porabil sem</Link>
            <Link to='/cart' onClick={handleSidebarClose}><span className='num-of-items-in-cart'>{localStorageCartNum}</span> Oddaj naročilo</Link>
            <Link to='/userprofile' onClick={handleSidebarClose}>Moj profil</Link>
            <Link to='/' onClick={handleLogout}>Izpis</Link>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SideBar
