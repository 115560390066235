
import { useState } from 'react'

import { getFormData } from '../../helpers/form'
import { lightFormat } from 'date-fns'

import { useVacationsCreate } from '../../api/vacations/useVacationsCreate'
import Loader from '../../components/common/Loader'
import { useNavigate } from 'react-router-dom'

const VacationsCreate = (props) => {
  const [endDate, setEndDate] = useState(null)
  const mutation = useVacationsCreate()
  const navigate = useNavigate()

  const submitHandler = (e) => {
    e.preventDefault()
    const formData = getFormData(e.target)

    mutation.mutateAsync(formData)
      .then((res) => { if (res) navigate('/vacations') })
  }

  if (mutation.isLoading) return <Loader />

  return (
    <div className='tlps-vacations'>
      <form onSubmit={submitHandler} className='vacation-form'>
        <div className='tlps-vacation__date tlps-userstock__item tlps-user-vacation__date'>
          <label htmlFor='startDate'><h3 className='vacation-date__title'>Začetek dopusta:</h3></label>
          <input type='date' id='vacation-start' name='vacationStart' min={lightFormat(new Date(), 'yyyy-MM-dd')} onChange={(e) => setEndDate(e.target.value)} required />
        </div>
        <div className='tlps-vacation__date tlps-userstock__item tlps-user-vacation__date'>
          <label htmlFor='endDate'><h3 className='vacation-date__title'>Konec dopusta:</h3></label>
          <input type='date' id='vacation-start' name='vacationEnd' min={endDate} disabled={!endDate} required />
        </div>
        <div className='tlps-userstock__item-footer__buttons'>
          <button className='btn btn-add-order' type='submit'>ODDAJ ZAHTEVO</button>
        </div>
      </form>
    </div>
  )
}

export default VacationsCreate
