import { useMutation, useQueryClient } from 'react-query'

import { axiosInstance, withAuthorization } from '../../axios/axiosInstance'
import queryKeys from '../../react-query/queryKeys'

import { toast } from 'react-toastify'

const createOrderUsage = async (data) => {
  const rsp = await axiosInstance(withAuthorization({
    url: `/orders/${data.id}/usage`,
    method: 'POST',
    data: data.payload,
    params: data.params
  }))
  return rsp.data
}

export const useOrderUsageCreate = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation(createOrderUsage, {

    onSuccess: (rsp) => {
      queryClient.invalidateQueries([queryKeys.orders])
      queryClient.invalidateQueries([queryKeys.taskUsage])
      queryClient.invalidateQueries([queryKeys.userStock])
      toast.success('Narucilo je uspesno kreirano')
    },
    onError: () => { toast.error('Greska prilikom kreiranja narucila') }
  })
  return mutation
}

export default { useOrderUsageCreate }
