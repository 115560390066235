import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { SideBarContext, UserInfoContext } from '../context/GlobalContext'

import Header from '../components/common/Header'
import SideBar from '../components/common/SideBar'
import Loader from '../components/common/Loader'

export default function Home () {
  const { sidebar, setSidebar } = useContext(SideBarContext)
  const { user } = useContext(UserInfoContext)

  function handleLinks () {
    if (sidebar === true) {
      setSidebar(false)
    }
  }

  if (!user) return <Loader />

  return (
    <div className='tlps-home'>
      <Header />
      <SideBar />
      <div className='tlps-home__buttons'>
        <Link onClick={handleLinks} className='btn' to={`/user/${user.id}/stock`}>MOJA ZALOGA</Link>
        <Link onClick={handleLinks} className='btn' to='/products'>NAROČI</Link>
        <Link onClick={handleLinks} className='btn' to={`/user/${user.id}/stock/usage`}>PORABIL SEM</Link>
        {/* <Link onClick={handleLinks} className='btn' to='/takepicture'>
            TAKE PHOTO
          </Link> */}
        <Link onClick={handleLinks} className='btn' to={`/user/${user.id}/stock/transfer`}>PRENOS MED UPORABNIKI</Link>
        <Link onClick={handleLinks} className='btn' to={`/user/${user.id}/stock/return`}>VRNITE BLAGO V SKLADIŠČE</Link>
        <Link onClick={handleLinks} className='btn' to='/tasks'>NALOGI</Link>
        <Link onClick={handleLinks} className='btn' to='/vacations'>DOPUST</Link>
      </div>

    </div>
  )
}
