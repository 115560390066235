import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { UserInfoContext, OrderTypeContext } from '../../context/GlobalContext'

import { useOrders } from '../../api/orders/useOrders'
import Loader from '../../components/common/Loader'
import { Card, Typography } from '@mui/material'

function TaskList (props) {
  const navigate = useNavigate()
  const { user } = useContext(UserInfoContext)
  const { orderType, setOrderType } = useContext(OrderTypeContext)
  const [filters, setFilters] = useState({ otherUser: user.id, supervisorUser: user.id, type: 'a1' })
  const orders = useOrders(filters)

  const handleFilterChange = (e) => {
    const { value } = e.target
    setOrderType(value)
    setFilters({ ...filters, type: value })
  }

  if (orders.isLoading) return <Loader />

  return (
    <div className='tlps-tasks'>
      <div className='tlps-tasks__filter m-bottom--l'>
        <select onChange={handleFilterChange} defaultValue={orderType}>
          <option value='a1'>A1</option>
        </select>
      </div>
      {orders.isSuccess
        ? orders.data.map((order, index) => {
          const data = order.updates ?? order
          return (
            <div className='tlps-orders' key={index} onClick={() => { navigate(`/tasks/${order.id}`) }}>
              <Card style={{ marginBottom: 10 }}>
                <div className='tlps-orders__wrap'>
                  <div>
                    <Typography variant='subtitle1' component='span' lineHeight={1.2}>
                      Stevilka naloga:
                    </Typography>
                    <Typography variant='subtitle1' component='span' style={{ marginLeft: 10 }} lineHeight={1.2}>
                      {data.serial}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle1' component='span' lineHeight={1.2}>
                      Kod naloga:
                    </Typography>
                    <Typography variant='subtitle1' component='span' style={{ marginLeft: 10 }} lineHeight={1.2}>
                      {data.code}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle1' component='span' lineHeight={1.2}>
                      Ime in priimek:
                    </Typography>
                    <Typography variant='subtitle1' component='span' style={{ marginLeft: 10 }} lineHeight={1.2}>
                      {data.full_name}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle1' component='span' lineHeight={1.2}>
                      Adresa:
                    </Typography>
                    <Typography variant='subtitle1' component='span' style={{ marginLeft: 10 }} lineHeight={1.2}>
                      {data.street ? data.street + ' ' + data.house_number + ', ' + data.city : 'N/A'}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle1' component='span' lineHeight={1.2}>
                      Status:
                    </Typography>
                    <Typography variant='subtitle1' component='span' style={{ marginLeft: 10 }} lineHeight={1.2}>
                      {data.status.name}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle1' component='span' lineHeight={1.2}>
                      Datum:
                    </Typography>
                    <Typography variant='subtitle1' component='span' style={{ marginLeft: 10 }} lineHeight={1.2}>
                      {new Date(data.created_at).toLocaleDateString()}
                    </Typography>
                  </div>
                </div>
              </Card>
            </div>
          )
        })
        : 'Nimate nobenih naloga'}
    </div>
  )
}

export default TaskList
