import { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useUserStockUsageCreate } from '../../api/userstockusage/useUserStockUsageCreate'
import { useWarehouseTypes } from '../../api/warehouse/useWarehouseTypes'
import { getFormData } from '../../helpers/form'

import { Input, Select, Submit, Textarea } from '../common/Form'

function UserStockUsageItem ({ item }) {
  const [open, setOpen] = useState(false)
  const { id } = useParams()
  const mutation = useUserStockUsageCreate()
  const warehouse = useWarehouseTypes()
  const whOptions = useMemo(() => warehouse.isSuccess && warehouse.data.map((wh) => [wh.id, wh.name]), [warehouse]) || []

  const handleSubmit = (e) => {
    e.preventDefault()

    const formData = getFormData(e.target)

    if (!formData.stock || formData.stock === '') return toast.error('Vnesi količino!')

    if (Number.parseInt(formData.stock) > item.stock) return toast.error('Nimaš dovolj izdelkov na zalogi!')

    mutation.mutate({ id, payload: { ...formData, product_id: item.product_id } })
  }

  return (
    <div className={open === true ? 'tlps-userusage__item open' : 'tlps-userusage__item'}>
      <div className='tlps-userusage__item-header' onClick={() => setOpen(!open)}>
        <div className='tlps-userusage__item-header__title'>
          <h3>{item.product.name}</h3>
        </div>
        <div className='tlps-userusage__item-header__qty'>
          <span>{item.stock}</span>
          <p>{item.product.unit_short_name}</p>
        </div>
      </div>
      <div className='tlps-userusage__item-footer'>
        <div className='tlps-userusage__item-footer__data'>
          <div className='tlps-userusage__item-footer__data__in-stock'>
            <span>Na zalogi v skladišču:</span>
            <span>{item.product.stock}</span>
            <p>{item.product.unit_short_name}</p>
          </div>
          <div>
            <span>SN:</span><span>{item.product.serial_num}</span>
          </div>
          <div>
            <span>KT:</span><span>{item.product?.category.name}</span>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='tlps-userusage__item-footer__inputs'>
            <Select options={whOptions} placeholder='Izberi operaterja/podjetje' name='warehouse_type_id' />
            <Input placeholder='Vnesi ime in priimek ali podjetje lokacije' name='name' />
            <Textarea placeholder='Vnesi naslov lokacije del' name='location' />
          </div>
          <div className='tlps-userusage__item-footer__buttons'>
            <Input type='number' placeholder='vnesi količino' name='stock' />
            <Submit className='btn-add-order'>ODDAJ</Submit>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UserStockUsageItem
