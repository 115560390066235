import axios from 'axios'
import { queryClient } from '../react-query/queryClient'
import queryKeys from '../react-query/queryKeys'
import { apiUrlV2 } from '../static/url'
import { getStoredToken } from '../storage/tokenStorage'

const config = {
  baseURL: apiUrlV2,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
}

export const withAuthorization = (requestConfig) => {
  const storedToken = getStoredToken()

  if (!storedToken) return requestConfig
  requestConfig.headers = { ...requestConfig.headers, Authorization: `Bearer ${storedToken}` }
  return requestConfig
}

export const axiosInstance = axios.create(config)

axiosInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.code === 'ERR_CANCELED') return Promise.resolve({ data: 'Request canceled', status: 499 })
    if (err.code === 'ERR_BAD_REQUEST' && err?.response?.status === 401 && !err?.config?.url?.startsWith('/auth')) {
      queryClient.refetchQueries({ queryKey: [queryKeys.user] })
    }
    return Promise.reject(err)
  }
)

export default {
  withAuthorization,
  axiosInstance
}
